<div style="margin-top: 10px;">
  <div id="scroll-to-top"></div>
  <app-complaints-section-nav-bar (dialogdata)="dialoguedata($event)"></app-complaints-section-nav-bar>
</div>
<div style="float: right;">
  <a class="text-capitalize exportcls" *ngIf="!IsDashboardExcelGenerating && isExportAvailable()" (click)="ExportDashboardComplaints()"><span class="glyphicon glyphicon-save export-btn"></span>&nbsp;Export to excel</a>
  <span *ngIf="IsDashboardExcelGenerating" style="margin-top: -15px;float:right;"><i class="fa fa-circle-o-notch fa-spin"></i>&nbsp;Generating...</span>
</div>
<div class="panel panel-default " style="margin-top: 30px;">
  <div class="panel-heading">
    <ul class="nav nav-tabs flex-column flex-sm-row">
      <li class="nav-item flex-sm-fill">
        <a class="nav-link" [ngClass]="{'active': isActionsViewSelected}" (click)="showActionsView()">{{'dashb_view_actions_tab_lbl' | translate}}</a>
      </li>
      <li class="nav-item flex-sm-fill">
        <a class="nav-link" [ngClass]="{'active': isComplaintsViewSelected}" (click)="showComplainsView()">{{'dashb_view_complaints_tab_lbl' | translate}}</a>
      </li>
    </ul>
  </div>
  <div class="panel-body bg-white1 pb-0">
    <div class="row  pb-0">
      <div class="col-lg-2 col-md-2 col-sm-2 ps-0 pe-0 removePadMobView left-panel">
         <div *ngIf="isActionsViewSelected">
             <app-action-filters-section [ReceiveActionCount]="actionsCount" [ReceiveActiveActionFilterCodes]="activeActionFilterCodes" (actionFiltersChanged)="actionFiltersChanged()" (autoFiltersStatus)="recieveAutoActionFilters($event)"></app-action-filters-section>
         </div>
         <div *ngIf="isComplaintsViewSelected">
          <app-status-filter-section [ReceiveStatusCount]="statusesCount" [ReceiveActiveStatusFilterCodes]="activeStatusFitlerCodes" (statusFiltersChanged)="statusFiltersChanged()" ></app-status-filter-section>
         </div>
         <div *ngIf="isComplaintsViewSelected">
          <app-roles-filter-section [ReceiveRolesCount]="rolesCount" [ReceiveActiveRoleFilterCodes]="activeRoleFitlerCodes" (roleFiltersChanged)="roleFiltersChanged()"></app-roles-filter-section>
         </div>
         <div *ngIf="isComplaintsViewSelected">
          <app-class-filters-section [ReceiveClassesCount]="classesCount" [ReceiveActiveClassFilterCodes]="activeClassFilterCodes" (classFiltersChanged)="classFiltersChanged()"></app-class-filters-section>
         </div>
      </div>
      <div class="col-s-10 col-md-10 col-sm-10 col-lg-10 new removePadMobView pe-0 right-panel">
        <span *ngIf="isCompletedActive && isComplaintsViewSelected" class="text-danger text-center">{{'dashboard_completed_complaints_info' | translate}}</span>
        <div class="panel panel-default mt-0">
            <div class="panel-body pt-0 ps-0 pe-0 phone_panel">
                <div *ngIf="isActionsViewSelected">
                  <div style="text-align: center;" *ngIf="complaintsActionsViewFiltered.length < 1">
                    <span>{{'dashb_msg_no_actions2' | translate}}</span>
                  </div>
                  <app-complaints-section *ngIf="complaintsActionsViewFiltered.length > 0" [cmpPageSize]="cmpPageSize" [custInfoCodes]="cmpCustomInfoCodes"
                [inputComplaints]="complaintsActionsViewFiltered" [changesApplied]="isSearchSomething"></app-complaints-section>
                </div>
                <div *ngIf="isComplaintsViewSelected">
                  <div style="text-align: center;" *ngIf="complaintsComplaintsViewFiltered.length < 1">
                    <span>{{'dashb_msg_no_complaints2' | translate}}</span>
                  </div>
                   <app-complaints-section *ngIf="complaintsComplaintsViewFiltered.length > 0" [cmpPageSize]="cmpPageSize" [custInfoCodes]="cmpCustomInfoCodes"
                [inputComplaints]="complaintsComplaintsViewFiltered" [changesApplied]="isSearchSomething"></app-complaints-section>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner bdColor="rgba(51, 51, 51, 0.3)" color="#676767" size="medium" type="ball-spin-clockwise">
  <p style="color: #676767; padding: 10px; font-weight: bold; ">Please Wait... </p>
</ngx-spinner>

