import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SessionStorageServiceService {

  constructor() { }

  setData = function (key, value) {
    if (typeof value === 'object') {
        value = JSON.stringify(value);
    }
    sessionStorage.setItem(key, value);
};
 getData = function (key) {
    var itemFromStorage = sessionStorage.getItem(key);
    if (!itemFromStorage || itemFromStorage === 'null') {
        return null;
    }
    if (itemFromStorage.charAt(0) === "{" || itemFromStorage.charAt(0) === "[") {
        return JSON.parse(itemFromStorage);
    }
    return itemFromStorage;
};

}
