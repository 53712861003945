import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { CustomdatePipe } from './reusables/pipes/customdate.pipe';
import { CustomDatePipePipe } from './reusables/custom-date-pipe/custom-date-pipe.pipe';
import { ShortenComplaintSubjectPipe } from './reusables/pipes/shorten-complaint-subject.pipe';
import { ToolTipDirective } from './reusables/directives/tool-tip/tool-tip.directive';
import { TooltipModule } from 'primeng/tooltip';
import { DaysagoPipe } from './reusables/pipes/daysago.pipe';
import { DaysleftPipe } from './reusables/pipes/daysleft.pipe';

@NgModule({
  imports: [
    CommonModule,
    TooltipModule
  ],
  declarations: [
    CustomdatePipe,
    CustomDatePipePipe,
    ShortenComplaintSubjectPipe,
    ToolTipDirective,
    DaysagoPipe,
    DaysleftPipe
  ],
  providers: [
    // DatePipe
  ],
  exports: [
    CustomdatePipe,
    CustomDatePipePipe,
    ShortenComplaintSubjectPipe,
    ToolTipDirective,
    DaysagoPipe,
    DaysleftPipe
  ]
})
export class SharedModule { }
