import { Injectable } from '@angular/core';
import { LocalStorageServiceService } from './local-storage-service.service';

@Injectable({
  providedIn: 'root'
})
export class MyActionDashboardSettingsService {

  serviceId: string = "myActionsDashboardSettingsService";
  settingsKey: string = 'myactionsfiltersettings';

  constructor(private lclStorage:LocalStorageServiceService) { }

  getUserSettings() {
    let storedSettings = this.lclStorage.getFromLocalStorage(this.settingsKey) || null;

    let statusSettings = storedSettings != null ? storedSettings.statusSettings : null;
    let roleSettings = storedSettings != null ? storedSettings.roleSettings : null;


    let statusesInfo = {
      draft: {
        isActive: statusSettings != null ? statusSettings.draft.isActive : true,
        count: 0
      },
      inProgress: {
        isActive: statusSettings != null ? statusSettings.inProgress.isActive : true,
        count: 0
      },
      completed: {
        isActive: statusSettings != null ? statusSettings.completed.isActive : true,
        count: 0
      }
    }

    let rolesInfo = {
      actionOwner: {
        isActive: roleSettings != null ? roleSettings.actionOwner.isActive : true,
        count: 0
      },
      actionCreator: {
        isActive: roleSettings != null ? roleSettings.actionCreator.isActive: true,
        count: 0
      }
    }
    if(storedSettings == null) this.lclStorage.addToLocalStorage(this.settingsKey, {
      rolesInfo: rolesInfo,
      statusesInfo: statusesInfo
    });
    return {
      rolesInfo: rolesInfo,
      statusesInfo: statusesInfo
    }
  }

  updateRoleSettings(newRoleSettings) {
    let storedSettings = this.lclStorage.getFromLocalStorage(this.settingsKey);
    storedSettings.roleSettings = newRoleSettings;
    this.lclStorage.addToLocalStorage(this.settingsKey, storedSettings);
  }

  updateStatusesSettings(newStatusSettings) {
    let storedSettings = this.lclStorage.getFromLocalStorage(this.settingsKey);
    storedSettings.statusSettings = newStatusSettings;
    this.lclStorage.addToLocalStorage(this.settingsKey, storedSettings);
  }


}

