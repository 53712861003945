import { Component, Input, OnInit } from '@angular/core';

export class EmployeeProfileModal{

    "id": string;
    "displayGeid": string;
    "email": string;
    "nameLn": string;
    "firstName": string;
    "lastName": string;
    "deactivateDate": string;
    "name": string;
    "nameLatin": string;
    "phone": string;
    "address": AddressDetailsModal;
    "isActive": boolean;
}

export class AddressDetailsModal{
  "city": string;
  "cityUniqueKey": string;
  "cityLatin": string;
  "countryCode": string;
  "addressId": string;
  "address": string;
  "zipCode": string;
}

@Component({
  selector: 'app-employeeprofile',
  templateUrl: './employeeprofile.component.html',
  styleUrls: ['./employeeprofile.component.css']
})
export class EmployeeprofileComponent implements OnInit {

  @Input('EmployeeData') EmployeeData!:EmployeeProfileModal;

  constructor() { }

  ngOnInit(): void {
  }

}
