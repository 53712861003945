import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common'

@Pipe({
  name: 'customDatePipe'
})
export class CustomDatePipePipe implements PipeTransform {

  constructor(private datePipe : DatePipe){}

  transform(value: any, ...args: any[]): any {
    if(value)
    {
      //console.log(value);
      const date = new Date(value);
      return this.datePipe.transform(date, 'yyyy-MM-dd');
    }
    return null;
  }

}
