<div class="row">
  <div class="col-md-12">
    <p
      *ngIf="actionsToPresent?.length == 0"
      style="margin: 0px; text-align:center"
    >
    {{'my_actions_dashboard_no_actions' | translate}}
    </p>
    <div *ngFor="let action of actionsToPresent | paginate: config">
      <div class="well well-sm" style="padding: 10px;">
        <div>
          <span appToolTip class="action-label" [tooltip]="action.name" placement="top">
            <span class="actionplan-label">
              <a target="_blank" routerLink="/issues/{{action.ccrpNo}}/actionplan/card/{{action.id}}">{{action.name | shortenComplaintSubject : 72}}</a>
            </span>
          </span>
          <span
            *ngIf="action.itemStatus == 2 || action.itemStatus == 4"
            class="badge badge-primary"
            style="float: right; border-radius: 3px !important"
          >
          {{'action_status_in_progress_lbl' | translate}}
          </span>
          <span
            *ngIf="action.itemStatus == 1"
            class="badge badge-secondary"
            style="float: right; border-radius: 3px !important"
          >
          {{'action_status_draft_lbl' | translate}}
          </span>

          <span
            *ngIf="action.itemStatus == 3"
            class="badge badge-secondary"
            style="float: right; border-radius: 3px !important"
          >
          {{'action_status_completed_lbl' | translate}}
          </span>
        </div>
        <hr class="my_issue_horizontalLine" style="margin-bottom: 10px; margin-top: 10px" />

        <div style="margin: 0 0 0 0">
          <span class="dashboard-item-data-label">{{'my_actions_dashboard_item_type' | translate}}: </span>
          {{ action.actionTypeName }}
        </div>
        <div style="margin: 0 0 0 0">
          <span class="dashboard-item-data-label">{{'my_actions_dashboard_ccrp_no' | translate}}: </span>
          {{ action.ccrpNo }}
        </div>
        <div style="display: inline-flex;">
          <span class="dashboard-item-data-label">{{'my_actions_dashboard_ru_info' | translate}}: </span>
         <span class="resolvingUnitWidth">
          <span>
            {{ action.resolvingUnit.divisionCode }}
            {{ action.resolvingUnit.buCode }} ({{
              action.resolvingUnit.pgCode
            }}) {{ action.resolvingUnit.pgName }}
            {{ action.resolvingUnit.laName }}
          </span>
          <span *ngIf="action.resolvingUnit.isGlobal"> | GLOBAL </span>
          <span *ngIf="action.resolvingUnit.addressInfo">
            {{ action.resolvingUnit.addressInfo }}
          </span>
        </span>
        </div>

        <hr class="my_issue_horizontalLine" style="margin-bottom: 10px; margin-top: 10px" />
        <span>
          <span style="margin-right: 15px">
            <span class="dashboard-item-data-label">{{'my_actions_dashboard_action_owner' | translate}}: </span>
            <span *ngIf="action.actionOwner">
              <app-employeeprofile
                [EmployeeData]="action.actionOwner"
              ></app-employeeprofile>
            </span>
          </span>
          <span style="margin-right: 15px">
            <span class="dashboard-item-data-label">{{'my_actions_dashboard_action_creator' | translate}}: </span>
            <span *ngIf="action.actionCreator">
              <app-employeeprofile
                [EmployeeData]="action.actionCreator"
              ></app-employeeprofile>
            </span>
          </span>
          <span>
            <span class="dashboard-item-data-label">{{'my_actions_dashboard_resolution_owner' | translate}}: </span>
            <span *ngIf="action.resolutionOwner">
              <app-employeeprofile
                [EmployeeData]="action.resolutionOwner"
              ></app-employeeprofile>
            </span>
          </span>
          <span>
          <span class="actions_deadline mx-2">
          <span class="phone_view">
            <span *ngIf="action.targetDate" class="dashboard-item-data-label"
              >{{'my_actions_dashboard_deadline' | translate}}:
            </span>
            <span class="deadline-text">{{ action.targetDate | customdate }}</span>
          </span>
          </span>
        </span>
        </span>
      </div>
    </div>
    <div *ngIf="actionsToPresent.length > 0" class="d-flex justify-content-center mt-3">
        <!-- <pagination-controls previousLabel="" nextLabel="" (pageChange)="config.currentPage=$event">
        </pagination-controls> -->
        <div class="row text-center" *ngIf='actions.length > 0'>
              <ul class="pagination">
                  <li class="{{this.hasPrevious()?'':'disabled'}}" (click)="this.previousClicked()"><a >&laquo;</a></li>

                  <li *ngIf="this.showFirstPage()" (click)="this.gotoPage(firstPage)"><a >{{firstPage}}</a></li>
                  <li *ngIf="this.showFirstPageSeparator()"><span>...</span></li>

                  <li *ngIf="this.hasPrevious()" (click)="this.gotoPage(this.previousPage())"><a >{{this.previousPage()}}</a></li>
                  <!-- <li *ngIf="!this.hasPrevious()"><span>&nbsp;</span></li> -->
                  <li class="active"><a >{{currentPage}}</a></li>
                  <li *ngIf="this.hasNext()" (click)="this.gotoPage(this.nextPage())"><a >{{this.nextPage()}}</a></li>
                  <!-- <li *ngIf="!this.hasNext()"><span>&nbsp;</span></li> -->

                  <li *ngIf="this.showLastPageSeparator()"><span>...</span></li>
                  <li *ngIf="this.showLastPage()" (click)="this.gotoPage(pagesCount)"><a >{{pagesCount}}</a></li>

                  <li class="{{this.hasNext()?'':'disabled'}}"><a  (click)="this.nextClicked()">&raquo;</a></li>
              </ul>
      </div>
    </div>
  </div>
</div>
