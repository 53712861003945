import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MyActionDashboardSettingsService } from 'src/app/services/my-action-dashboard-settings.service';
import { MyActionDashboardService } from 'src/app/services/my-action-dashboard.service';
import * as _ from 'lodash';
import { SessionStorageServiceService } from 'src/app/services/session-storage-service.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';

export enum ActionItemStatus {
  New = 0,
  Todo = 1,
  InProgress = 2,
  InProgressOverdue = 4,
  Completed = 3
}

@Component({
  selector: 'app-myissue-action-plan-dashboard',
  templateUrl: './myissue-action-plan-dashboard.component.html',
  styleUrls: ['./myissue-action-plan-dashboard.component.css']
})
export class MyissueActionPlanDashboardComponent implements OnInit {

  displayedActions: any[];
  dataLoaded: boolean = true;
  settings: any;
  statusesInfo: any;
  rolesInfo: any;
  orderBy: any = 'targetDate';
  preOrderBy: any;
  orderMode: string = 'asc';
  preOrderMode: string;
  user!: any ;
  userActions: any[];
  orderByExpression: string;
  prevRolesInfo!: any;
  prevStatusInfo!: any;
  prevSearchInfo!: any;
  searchKeyword: any;
  isSearchSomething: boolean = false;
  excelDownloading:boolean = false;

  constructor(private actionService: MyActionDashboardService, private actionSettingsService: MyActionDashboardSettingsService,
    private sessionStrg: SessionStorageServiceService,private spinner: NgxSpinnerService,
    public breadcrumbService: BreadcrumbService) {

  }

  ngOnInit(): void {
    this.breadcrumbService.menuItems = [
      {label: 'DASHBOARD', routerLink: '/mydashboard'},
      {label: 'ACTION PLAN DASHBOARD'}
    ];

    this.user = this.sessionStrg.getData('userInfo').geid
    this.settings = this.actionSettingsService.getUserSettings();
    this.statusesInfo = this.settings.statusesInfo;
    this.rolesInfo = this.settings.rolesInfo;
    this.prevRolesInfo = this.rolesInfo;
    this.prevStatusInfo = this.prevStatusInfo;
    setTimeout(() => this.spinner.show(), 1);
    this.actionService.getActionsForUser(this.user).subscribe(data => {
      this.userActions = data;
      const filteredByRole = this.filterItemsByRole(this.userActions, this.rolesInfo);
      this.countItemsPerStatus(filteredByRole);
      const filteredByStatus = this.filterItemsByStatus(this.userActions, this.statusesInfo);
      this.countItemsPerRole(filteredByStatus);
      this.isSearchSomething = false;
      this.displayedActions = _.orderBy(this.filterItemsByStatus(filteredByRole, this.statusesInfo), this.orderByExpression);
      this.spinner.hide();
    });

  }

  ngDoCheck() {

    if (this.rolesInfo !== this.prevRolesInfo) {
      this.prevRolesInfo = { ...this.rolesInfo };
      const roleInfoChanged = (prevRolesInfo: any) => {
        if (!this.userActions) {
          return;
        }
        this.actionSettingsService.updateRoleSettings(prevRolesInfo);

        const filteredActions = this.filterItemsByRole(this.userActions, prevRolesInfo);

        this.countItemsPerStatus(filteredActions);
        this.isSearchSomething = false;
        this.displayedActions = _.orderBy(this.filterItemsByStatus(filteredActions, this.statusesInfo), this.orderByExpression);

      }

      roleInfoChanged(this.prevRolesInfo);
    }

    if (this.statusesInfo !== this.prevStatusInfo) {
      this.prevStatusInfo = { ...this.statusesInfo };
      const statusInfoChanged = (prevStatusInfo: any) => {
        if (!this.userActions) {
          return;
        }
        this.actionSettingsService.updateStatusesSettings(prevStatusInfo);
        const filteredActions = this.filterItemsByStatus(this.userActions, prevStatusInfo);
        this.countItemsPerRole(filteredActions);
        this.isSearchSomething = false;
        this.displayedActions = _.orderBy(this.filterItemsByRole(filteredActions, this.rolesInfo), this.orderByExpression);

      }
      statusInfoChanged(this.prevStatusInfo);
    }

    if (this.searchKeyword !== this.prevSearchInfo) {
      this.prevSearchInfo = {...this.searchKeyword};

      let filteredActions = this.filterItemsByRole(this.userActions, this.rolesInfo);
      this.countItemsPerStatus(filteredActions);
      filteredActions = this.filterItemsByStatus(filteredActions, this.statusesInfo);
      if (this.prevSearchInfo) {

        filteredActions = _.filter(filteredActions, (itm) => {
          if (itm['actionOwner'] && itm['actionOwner'].name.toLocaleLowerCase().includes(this.searchKeyword.toLocaleLowerCase()) ) return true;
          if (itm['ccrpNo'].toLocaleLowerCase().includes(this.searchKeyword.toLocaleLowerCase()) ) return true;
          if (itm['actionCreator'] && itm['actionCreator'].name.toLocaleLowerCase().includes(this.searchKeyword.toLocaleLowerCase()) ) return true;
          if (itm['resolutionOwner'] && itm['resolutionOwner'].name.toLocaleLowerCase().includes(this.searchKeyword.toLocaleLowerCase()) ) return true;
          if (itm['actionTypeName'].toLocaleLowerCase().includes(this.searchKeyword.toLocaleLowerCase()) ) return true;
          if (itm['name'].toLocaleLowerCase().includes(this.searchKeyword.toLocaleLowerCase()) ) return true;
          if (itm["resolvingUnit"] && itm["resolvingUnit"].divisionCode.toLocaleLowerCase().includes(this.searchKeyword.toLocaleLowerCase()) ) return true;
          if (itm["resolvingUnit"] && itm['resolvingUnit'].buCode.toLocaleLowerCase().includes(this.searchKeyword.toLocaleLowerCase()) ) return true;
          if (itm["resolvingUnit"] && itm['resolvingUnit'].pgCode.toLocaleLowerCase().includes(this.searchKeyword.toLocaleLowerCase()) ) return true;
          return false;
        });
        // filteredActions = filteredActions.filter(action => {
        //   const cmpString = JSON.stringify(action).toLowerCase();
        //   return cmpString.includes(this.searchKeyword.toLocaleLowerCase());
        // });
        if(this.searchKeyword) {
          this.isSearchSomething = true;
        }
        else {
          this.isSearchSomething = false;
        }

        this.displayedActions = filteredActions;
      }

    }

    if (this.orderBy !== this.preOrderBy) {
      this.displayedActions = _.orderBy(this.displayedActions, this.orderBy, (this.orderMode === 'desc') ? 'desc' : 'asc');
    }
  }

  // Filter Actions By Role
  filterItemsByRole(items: any, roleFilters: any): any[] {
    let actionOwner = roleFilters['actionOwner'].isActive;
    let actionCreator = roleFilters['actionCreator'].isActive;

    const filteredActions = _.filter(items, (itm) => {
      if (actionOwner === true && (itm['actionOwner'] && itm['actionOwner'].id == this.user)) return true; // removed GEID
      if (actionCreator === true && (itm['actionCreator'] && itm['actionCreator'].id == this.user)) return true;

      return false;
    });

    return filteredActions;
  }

  //Filter Actions By Status
  filterItemsByStatus(items: any, statusFilters: any) {
    let draft = statusFilters['draft'].isActive;
    let inProgress = statusFilters['inProgress'].isActive;
    let completed = statusFilters['completed'].isActive;
    const filteredActions = _.filter(items,
      itm => {
        if (draft == true && itm['itemStatus'] == ActionItemStatus.Todo) return true;
        if (inProgress == true && itm['itemStatus'] == ActionItemStatus.InProgress || inProgress == true && itm['itemStatus'] == ActionItemStatus.InProgressOverdue) return true;
        if (completed == true && itm['itemStatus'] == ActionItemStatus.Completed) return true;
        return false;
      });
    return filteredActions;
  }

  //Counts Per Role and Status

  countItemsPerRole(itemsToCount) {
    this.rolesInfo.actionOwner.count = 0;
    this.rolesInfo.actionCreator.count = 0;
    itemsToCount.forEach(itm => {
      if (itm.actionOwner && itm.actionOwner.id == this.user) this.rolesInfo.actionOwner.count++; //Removed GEID
      if (itm.actionCreator && itm.actionCreator.id == this.user) this.rolesInfo.actionCreator.count++;
    });
    this.rolesInfo = Object.assign({}, this.rolesInfo);
  }

  countItemsPerStatus(itemsToCount) {

    this.statusesInfo.draft.count = 0;
    this.statusesInfo.inProgress.count = 0;
    this.statusesInfo.completed.count = 0;

    itemsToCount.forEach(itm => {
      if (itm.itemStatus == ActionItemStatus.Todo) this.statusesInfo.draft.count++;
      if (itm.itemStatus == ActionItemStatus.InProgress || itm.itemStatus == ActionItemStatus.InProgressOverdue) this.statusesInfo.inProgress.count++;
      if (itm.itemStatus == ActionItemStatus.Completed) this.statusesInfo.completed.count++;
    });

    this.statusesInfo = Object.assign({}, this.statusesInfo);
  }

  //Export To Excel
  exportActionItemsByGeid() {
    this.excelDownloading = true;
    let statuses = [];
    if (this.statusesInfo.draft.isActive) statuses.push(ActionItemStatus.Todo);
    if (this.statusesInfo.inProgress.isActive) {statuses.push(ActionItemStatus.InProgress); statuses.push(ActionItemStatus.InProgressOverdue)}
    if (this.statusesInfo.completed.isActive) statuses.push(ActionItemStatus.Completed);

    this.actionService.downloadMyActionBoardExcel({
      geid: this.user,
      statuses: statuses,
      roles: {
        actionOwner: this.rolesInfo.actionOwner.isActive,
        actionCreator: this.rolesInfo.actionCreator.isActive
      }
      }).subscribe((response:ArrayBuffer)=>{
        const blob = new Blob([response],{type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
        const downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(blob);
        downloadLink.download ='My Actions';
        downloadLink.click();
        this.excelDownloading = false;
      });
  }

}
