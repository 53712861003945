
<span class="highlightOnHover" style="cursor: pointer;" [autoClose]="false" (resize)="true" container="body" [ngbPopover]="popContent" popoverTitle="{{EmployeeData.name}}" [placement]="'auto'">{{EmployeeData.name}}</span>
<ng-template #popContent style="position:relative;">
  {{EmployeeData.address.countryCode}},{{EmployeeData.address.city}},{{EmployeeData.address.address}}
  <span><a href="https://maps.google.pl/?q={{EmployeeData.address.countryCode}}, {{EmployeeData.address.city}}, {{EmployeeData.address.address}}" target="_blank" class="gray-link">
     <i class="hitachiicon-location"> </i>
 </a></span> <br />
 <small>
  <i class="glyphicon glyphicon-envelope"></i><a href="mailto:{{EmployeeData.email}}" class="admin-icon-a">{{EmployeeData.email}}</a>
  <br />
  </small>
</ng-template>
