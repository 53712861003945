import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';


@Injectable({
  providedIn: 'root'
})
export class ToasterserviceService {

  constructor(private messageService: MessageService) { }

  showSuccess(messages:string):void{
   this.messageService.add({severity:'success', summary: `<b>${messages}</b>`,
   closable: true})
  }

  showInfo(messages:string):void{
    this.messageService.add({severity:'info', summary: `<b>${messages}</b>`,
    closable: true})
   }

   showWarning(messages:string):void{
    this.messageService.add({severity:'warn', summary: `<b>${messages}</b>`,
    closable: true})
   }

   showError(messages:string):void{
    this.messageService.add({severity:'error', summary: `<b>Error :</b> <br/> <b>${messages}</b>`,
    closable: true})
   }
}
