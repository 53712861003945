import { Component, OnInit } from '@angular/core';
import { DashboardService } from 'src/app/services/dashboard.service';
import { LocalStorageServiceService } from 'src/app/services/local-storage-service.service';
import { SessionStorageServiceService } from 'src/app/services/session-storage-service.service';
import * as _ from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { ViewportScroller } from '@angular/common';

@Component({
  selector: 'app-my-issue-dashboard',
  templateUrl: './my-issue-dashboard.component.html',
  styleUrls: ['./my-issue-dashboard.component.css']
})
export class MyIssueDashboardComponent implements OnInit {

  isActionsViewSelected!: boolean;
  isComplaintsViewSelected!: boolean;
  complaints!: any;
  complaintsActionsViewFiltered = [];
  complaintsComplaintsViewFiltered = [];
  isCompletedActive: boolean;
  search_keyword = '';
  order_by: any = 'targetResolvingDate';
  order_mode: string = 'asc';
  cmpCustomInfoCodes: number[] = this.lclStorageService.getFromLocalStorage("cmpCustomInfo") || [1, 2, 3, 17]; //[Customer,RU,ABB Prod, Complaint Type]
  p: number = 1;
  cmpPageSize = this.lclStorageService.getFromLocalStorage("cmpPageSize") || 5;
  isSearchSomething: boolean = false;
  IsDashboardExcelGenerating: boolean = false;
  tileVisibility!: any;
  //localStorageService.get("cmpCustomInfo") || [1, 2, 3, 17]; //[Customer,RU,ABB Prod, Complaint Type]

  //Default Action,Status,Roles and Classes Filters Count
  actionsCount =
    {
      toSubmit: 0,
      toAssign: 0,
      toResolve: 0,
      toComplete: 0,
      toVerifyResolution: 0,
      resRejected: 0,
      toResendSurvey: 0,
      rejectedByCoordinatorToDraft: 0,
      toReplaceInactiveRO: 0,
      toReplaceInactiveCO: 0,
      toReplaceInactiveRU: 0,
      returnedToNotAssigned: 0,
      toExecuteContainmentAction: 0,
      toCompleteAfterCustomerExecution: 0,
      toEffectivenessAndSustainabilityCheck: 0,
      total: 0
    };

  statusesCount =
    {
      draft: 0,
      notAssigned: 0,
      inProcess: 0,
      inProcOverdue: 0,
      solutionSubmitted: 0,
      inProcVer: 0,
      inProcVerOverdue: 0,
      completed: 0,
      completedExecutionPending: 0,
      total: 0
    }

  classesCount =
    {
      classA: 0,
      classB: 0,
      classI: 0,
      classC: 0,
      others: 0,
      unconfirmed: 0,
      total: 0
    }

  rolesCount =
    {
      complaintOwner: 0,
      complaintOwnerTeam: 0,
      coordinator: 0,
      resolutionOwner: 0,
      resolutionOwnerTeam: 0,
      inactiveComplaintOwnerSupervisor: 0,
      buProcessOwner: 0,
      divisionProcessOwner: 0,
      globalCCRPOwner: 0
    }
  selectedDialoguedata: any;
  toHighlight!: any;
  IssueDetails: any;
  dataLoaded: boolean = false;

  constructor(private myissueService: DashboardService, private lclStorageService: LocalStorageServiceService,
    private ssStorageService: SessionStorageServiceService,
    private spinner: NgxSpinnerService,
    private scroller: ViewportScroller,
    public breadcrumbService: BreadcrumbService) {
    this.isActionsViewSelected = true;
  }

  ngOnInit(): void {
    this.breadcrumbService.menuItems = [
      {label: 'DASHBOARD', routerLink: '/mydashboard'},
      {label: 'MY DASHBOARD'}
    ];

    if(this.ssStorageService.getData('userInfo')?.geid != undefined){
      this.loadComplaints(this.ssStorageService.getData('userInfo')?.geid)
    }
    else{
      this.myissueService.isGetGeid.subscribe(async geid =>{
        if(geid != "")
          this.loadComplaints(geid);
      })
      //setTimeout(() =>  this.spinner.show(),1);
          //setTimeout(() =>  this.loadComplaints(this.ssStorageService.getData('userInfo')?.geid), 5000);
    }
    this.scroller.scrollToAnchor("scroll-to-top"); 
    window.scrollTo(0, 0)
  }


  // Default Action,Status,Role and Classes Active Filter Codes

  activeActionFilterCodes = this.ssStorageService.getData("activeActionFilterCodes") || ['0' /*toSubmit*/, '1' /*toAssign*/,
    '2' /*toResolve*/, '3'/*toComplete*/,
    '4' /*toVerRes*/, '5'/*'ResRejected*/,
    '6' /*RejectedByCoorToDraft*/, '7' /*ToReplaceInactiveRO*/,
    '8' /*ToReplaceInactiveCO*/, '9' /*ToReplaceInactiveRU*/, '10' /*ReturnedToNotAssigned*/,
    '11' /*ToResendSurvey*/, '12' /*ToExecuteContainmentActions*/,
    '13' /*ToCompleteAfterCustomerExecution*/,
    '14' /*ToEffectivenessAndSustainabilityCheck*/
  ];

  activeStatusFitlerCodes = this.lclStorageService.getFromLocalStorage("activeStatusFitlerCodes") || ['2' /*Draft*/, '3'/*NotAssigned*/, '4'/*InProcess*/, '5'/*InProcOverdue*/, '7'/*Resolved*/, '11'/*InProcVer*/, '12' /*InProcVerOverdue*/, '13' /* Completed (Execution Pending)*/];
  activeRoleFitlerCodes = this.lclStorageService.getFromLocalStorage("activeRoleFitlerCodes") || ['3' /*CO*/,
    //'4'/*CO Supervisor*/,
    '5'/*CO Team*/,
    '6' /*Coordinator*/,
    '7' /*RO*/,
    '8' /*RO Team*/,
    '4' /*Complaint Owner Supervisor*/,
    '18' /*Bu Process Owner*/,
    '17' /*Division Process Owner*/,
    '1' /*Global CCRP Owner*/
  ];
  activeClassFilterCodes = this.lclStorageService.getFromLocalStorage("activeClassFilterCodes") || ['0' /*Class C*/,
    '1' /*Class A*/,
    '2' /*Class B*/,
    '3' /*Class I*/,
    '4' /*Old Issues*/,
    '5' /*Unconfirmed*/
  ];

  // Methods for Filter Changes

  recieveAutoActionFilters(data: any) {
    this.tileVisibility = Object.assign({}, data);
  }

  actionFiltersChanged = () => {
    this.isSearchSomething = false;
    this.refreshActionsView();
    this.ssStorageService.setData("activeActionFilterCodes", this.activeActionFilterCodes);
  };

  roleFiltersChanged = () => {
    this.isSearchSomething = false;
    this.refreshComplaintsView();
    this.lclStorageService.addToLocalStorage("activeRoleFitlerCodes", this.activeRoleFitlerCodes);

  };

  statusFiltersChanged = function () {
    this.isSearchSomething = false;
    this.refreshComplaintsView();
    this.lclStorageService.addToLocalStorage("activeStatusFitlerCodes", this.activeStatusFitlerCodes);

  };

  classFiltersChanged = function () {
    this.isSearchSomething = false;
    this.refreshComplaintsView();
    this.lclStorageService.addToLocalStorage("activeClassFilterCodes", this.activeClassFilterCodes);

  };


  //Method to execute when click on show Actions Tab
  showActionsView() {
    this.isActionsViewSelected = true;
    this.isComplaintsViewSelected = false;
    this.refreshView();
  }

  dialoguedata(eventData) {
    this.selectedDialoguedata = eventData;
    this.cmpCustomInfoCodes = eventData?.codes;
    this.cmpPageSize = eventData?.size;
  }

  //Method to execute when click on show complaints Tab
  showComplainsView() {
    this.isActionsViewSelected = false;
    this.isComplaintsViewSelected = true;
    this.refreshView();
  }

  // Method to fetch Complaints under Action I should take

  refreshActionsView() {

    this.toHighlight = {
      co: [],
      coIsSelected: false,
      ro: [],
      roIsSelected: false,
      ru: [],
      ruIsSelected: false
    };
    this.complaintsActionsViewFiltered.length = 0;
    let actionComplaints = [];

    if (this.search_keyword != '') {
      this.isSearchSomething = true;
      actionComplaints = this.complaints.filter(cmp => cmp.requiresUserAction)
        .filter(cmp => {
          const cmpString = JSON.stringify(cmp).toLowerCase();
          return cmpString.includes(this.search_keyword.toLocaleLowerCase());
        });
    } else {
      this.isSearchSomething = false;
      actionComplaints = this.complaints?.filter(
        function (cmp: any) {
          return cmp.requiresUserAction;
        });
    }


    this.recountActionView(actionComplaints);

    actionComplaints.filter(cmp => cmp.actionCodes.some(ac => this.activeActionFilterCodes.includes(ac)))
      .forEach(c => {
        this.complaintsActionsViewFiltered.push(c);
      });

    this.complaintsActionsViewFiltered = _.orderBy(this.complaintsActionsViewFiltered, this.order_by, (this.order_mode === 'desc') ? 'desc' : 'asc');

    this.toHighlight.roIsSelected = this.activeActionFilterCodes.indexOf('7') > -1;
    this.toHighlight.coIsSelected = this.activeActionFilterCodes.indexOf('8') > -1;
    this.toHighlight.ruIsSelected = this.activeActionFilterCodes.indexOf('9') > -1;

  }

  // Count of Roles,Status,Actions and Classes
  recountActionView = (actionComplaints: any) => {

    this.actionsCount.toSubmit = 0;
    this.actionsCount.toAssign = 0;
    this.actionsCount.toResolve = 0;
    this.actionsCount.toComplete = 0;
    this.actionsCount.toVerifyResolution = 0;
    this.actionsCount.resRejected = 0;
    this.actionsCount.rejectedByCoordinatorToDraft = 0;
    this.actionsCount.toReplaceInactiveRO = 0;
    this.actionsCount.toReplaceInactiveCO = 0;
    this.actionsCount.toReplaceInactiveRU = 0;
    this.actionsCount.returnedToNotAssigned = 0;
    this.actionsCount.toResendSurvey = 0;
    this.actionsCount.toExecuteContainmentAction = 0;
    this.actionsCount.toCompleteAfterCustomerExecution = 0;
    this.actionsCount.toEffectivenessAndSustainabilityCheck = 0;
    this.actionsCount.total = 0;

    actionComplaints.forEach((cmp: any) => {
      this.actionsCount.total++;

      if (cmp.actionCodes.indexOf("0") > -1) {
        this.actionsCount.toSubmit++;
      }
      if (cmp.actionCodes.indexOf("1") > -1) {
        this.actionsCount.toAssign++;
      }
      if (cmp.actionCodes.indexOf("2") > -1) {
        this.actionsCount.toResolve++;
      }
      if (cmp.actionCodes.indexOf("3") > -1) {
        this.actionsCount.toComplete++;
      }
      if (cmp.actionCodes.indexOf("4") > -1) {
        this.actionsCount.toVerifyResolution++;
      }
      if (cmp.actionCodes.indexOf("5") > -1) {
        this.actionsCount.resRejected++;
      }
      if (cmp.actionCodes.indexOf("6") > -1) {
        this.actionsCount.rejectedByCoordinatorToDraft++;
      }
      if (cmp.actionCodes.indexOf("7") > -1) {
        this.toHighlight.ro.push(cmp.number);
        this.actionsCount.toReplaceInactiveRO++;
      }
      if (cmp.actionCodes.indexOf("8") > -1) {
        this.toHighlight.co.push(cmp.number);
        this.actionsCount.toReplaceInactiveCO++;
      }
      if (cmp.actionCodes.indexOf("9") > -1) {
        this.toHighlight.ru.push(cmp.number);
        this.actionsCount.toReplaceInactiveRU++;
      }
      if (cmp.actionCodes.indexOf("10") > -1) {
        this.actionsCount.returnedToNotAssigned++;
      }
      if (cmp.actionCodes.indexOf("11") > -1) {
        this.actionsCount.toResendSurvey++;
      }
      if (cmp.actionCodes.indexOf("12") > -1) {
        this.actionsCount.toExecuteContainmentAction++;
      }
      if (cmp.actionCodes.indexOf('13') > - 1) {
        this.actionsCount.toCompleteAfterCustomerExecution++;
      }
      if (cmp.actionCodes.indexOf('14') > -1) {
        this.actionsCount.toEffectivenessAndSustainabilityCheck++;
      }
    });
    this.actionsCount = Object.assign({}, this.actionsCount);

  }

  recountStatusesSection = function (complaints) {
    this.statusesCount.draft = 0;
    this.statusesCount.notAssigned = 0;
    this.statusesCount.inProcess = 0;
    this.statusesCount.inProcOverdue = 0;
    this.statusesCount.solutionSubmitted = 0;
    this.statusesCount.inProcVer = 0;
    this.statusesCount.inProcVerOverdue = 0;
    this.statusesCount.completedExecutionPending = 0;
    this.statusesCount.completed = 0;
    this.statusesCount.total = 0;

    complaints.forEach((cmp: any) => {
      this.statusesCount.total++;

      if (cmp.statusId == 2) {
        this.statusesCount.draft++;
      }
      if (cmp.statusId == 3) {
        this.statusesCount.notAssigned++;
      }
      if (cmp.statusId == 4) {
        this.statusesCount.inProcess++;
      }
      if (cmp.statusId == 5) {
        this.statusesCount.inProcOverdue++;
      }
      if (cmp.statusId == 7) {
        this.statusesCount.solutionSubmitted++;
      }
      if (cmp.statusId == 8) {
        this.statusesCount.completed++;
      }
      if (cmp.statusId == 11) {
        this.statusesCount.inProcVer++;
      }
      if (cmp.statusId == 12) {
        this.statusesCount.inProcVerOverdue++;
      }
      if (cmp.statusId == 13) {
        this.statusesCount.completedExecutionPending++;
      }
    });
    this.statusesCount = Object.assign({}, this.statusesCount);

  }

  recountClassesSection = function (complaints) {
    this.classesCount.classA = 0;
    this.classesCount.classB = 0;
    this.classesCount.classI = 0;
    this.classesCount.classC = 0;
    this.classesCount.others = 0;
    this.classesCount.unconfirmed = 0;
    this.classesCount.total = 0;
    complaints.forEach((cmp) => {
      this.classesCount.total++;

      if (cmp.scoringMatrixCurrentRating != null) {
        if (cmp.complaintClass == null && cmp.scoringMatrixCurrentRating.class != 1) {
          this.classesCount.unconfirmed++;
        }
        if (cmp.scoringMatrixCurrentRating.class == 1) {
          this.classesCount.classA++;
        }
        if (cmp.scoringMatrixCurrentRating.class == 2 && cmp.complaintClass !== null) {
          this.classesCount.classB++;
        }
        if (cmp.scoringMatrixCurrentRating.class == 3 && cmp.complaintClass !== null) {
          this.classesCount.classI++;
        }
        if (cmp.scoringMatrixCurrentRating.class == 0 && cmp.statusId > 3) {
          this.classesCount.classC++;
        }
      }
      else if (cmp.scoringMatrixOriginalRating != null) {
        if ((cmp.complaintClass == null || (cmp.scoringMatrixOriginalRating.class == 0 && cmp.statusId <= 3)) && cmp.scoringMatrixOriginalRating.class != 1) {
          this.classesCount.unconfirmed++;
        }
        if (cmp.scoringMatrixOriginalRating.class == 1) {
          this.classesCount.classA++;
        }
        if (cmp.scoringMatrixOriginalRating.class == 2 && cmp.complaintClass !== null) {
          this.classesCount.classB++;
        }
        if (cmp.scoringMatrixOriginalRating.class == 3 && cmp.complaintClass !== null) {
          this.classesCount.classI++;
        }
        if (cmp.scoringMatrixOriginalRating.class == 0 && cmp.statusId > 3) {
          this.classesCount.classC++;
        }
      }
      else
        this.classesCount.others++;

    });
    this.classesCount = Object.assign({}, this.classesCount);
  }

  recountRolesSection = function (complaints) {

    this.rolesCount.complaintOwner = 0;
    this.rolesCount.complaintOwnerTeam = 0;
    this.rolesCount.coordinator = 0;
    this.rolesCount.resolutionOwner = 0;
    this.rolesCount.resolutionOwnerTeam = 0;
    this.rolesCount.inactiveComplaintOwnerSupervisor = 0;
    this.rolesCount.buProcessOwner = 0;
    this.rolesCount.divisionProcessOwner = 0;
    this.rolesCount.globalCCRPOwner = 0;

    complaints.forEach((cmp: any) => {
      if (cmp.roleCodes.indexOf("3") > -1) {
        this.rolesCount.complaintOwner++;
      }
      if (cmp.roleCodes.indexOf("5") > -1) {
        this.rolesCount.complaintOwnerTeam++;
      }
      if (cmp.roleCodes.indexOf("6") > -1) {
        this.rolesCount.coordinator++;
      }
      if (cmp.roleCodes.indexOf("7") > -1) {
        this.rolesCount.resolutionOwner++;
      }
      if (cmp.roleCodes.indexOf("8") > -1) {
        this.rolesCount.resolutionOwnerTeam++;
      }
      if (cmp.roleCodes.indexOf("4") > -1) {
        this.rolesCount.inactiveComplaintOwnerSupervisor++;
      }
      if (cmp.roleCodes.indexOf("1") > -1) {
        this.rolesCount.globalCCRPOwner++;
      }
      if (cmp.roleCodes.indexOf("17") > -1) {
        this.rolesCount.divisionProcessOwner++;
      }
      if (cmp.roleCodes.indexOf("18") > -1) {
        this.rolesCount.buProcessOwner++;
      }
    });
    this.rolesCount = Object.assign({}, this.rolesCount);
  }

  isCompletedTabActive = () => this.activeStatusFitlerCodes.indexOf('8') > -1;

  // Complaints Section
  refreshComplaintsView = function () {
    this.isCompletedActive = this.isCompletedTabActive();
    this.complaintsComplaintsViewFiltered.length = 0;
    var complaintsViewComplaints = [];

    if (this.search_keyword != '') {
      this.isSearchSomething = true;
      complaintsViewComplaints = this.complaints.filter(cmp => {
        const cmpString = JSON.stringify(cmp).toLowerCase();
        return cmpString.includes(this.search_keyword.toLocaleLowerCase());
      });
    } else {
      this.isSearchSomething = false;
      complaintsViewComplaints = this.complaints;
    }



    this.recountStatusesSection(complaintsViewComplaints.filter((cmp: any) => {
      if (cmp.scoringMatrixCurrentRating !== null && cmp.scoringMatrixCurrentRating !== undefined) {
        if (cmp.complaintClass == null && cmp.scoringMatrixCurrentRating.class !== 1) {
          return cmp.roleCodes.filter(roleCode => this.activeRoleFitlerCodes.indexOf(roleCode) !== -1).length > 0 && this.activeClassFilterCodes.indexOf("5") > -1;
        }
        return this.activeClassFilterCodes.indexOf(cmp.scoringMatrixCurrentRating.class.toString()) > -1 && cmp.roleCodes.filter(roleCode => this.activeRoleFitlerCodes.indexOf(roleCode) !== -1).length > 0;
      } else if (cmp.scoringMatrixOriginalRating !== null && cmp.scoringMatrixOriginalRating !== undefined) {
        if ((cmp.complaintClass == null || (cmp.scoringMatrixOriginalRating.class == 0 && cmp.statusId < 4)) && cmp.scoringMatrixOriginalRating.class !== 1) {
          return cmp.roleCodes.filter(roleCode => this.activeRoleFitlerCodes.indexOf(roleCode) !== -1).length > 0 && this.activeClassFilterCodes.indexOf("5") > -1;
        }
        return this.activeClassFilterCodes.indexOf(cmp.scoringMatrixOriginalRating.class.toString()) > -1 && cmp.roleCodes.filter(roleCode => this.activeRoleFitlerCodes.indexOf(roleCode) !== -1).length > 0;
      }
      return cmp.roleCodes.filter(roleCode => this.activeRoleFitlerCodes.indexOf(roleCode) !== -1).length > 0 && this.activeClassFilterCodes.indexOf("4") > -1;

    }));

    this.recountRolesSection(complaintsViewComplaints.filter((cmp: any) => {
      if (cmp.scoringMatrixCurrentRating != null && cmp.scoringMatrixCurrentRating != undefined) {
        if (cmp.complaintClass == null && cmp.scoringMatrixCurrentRating.class != 1) {
          return this.activeStatusFitlerCodes.indexOf(cmp.statusId.toString()) > -1 && this.activeClassFilterCodes.indexOf("5") > -1;
        }
        return this.activeClassFilterCodes.indexOf(cmp.scoringMatrixCurrentRating.class.toString()) > -1
          && this.activeStatusFitlerCodes.indexOf(cmp.statusId.toString()) > -1;
      }
      else if (cmp.scoringMatrixOriginalRating != null && cmp.scoringMatrixOriginalRating != undefined) {
        if ((cmp.complaintClass == null || (cmp.scoringMatrixOriginalRating.class == 0 && cmp.statusId < 4)) && cmp.scoringMatrixOriginalRating.class != 1) {
          return this.activeStatusFitlerCodes.indexOf(cmp.statusId.toString()) > -1 && this.activeClassFilterCodes.indexOf("5") > -1;
        }
        return this.activeClassFilterCodes.indexOf(cmp.scoringMatrixOriginalRating.class.toString()) > -1
          && this.activeStatusFitlerCodes.indexOf(cmp.statusId.toString()) > -1;
      }
      return this.activeStatusFitlerCodes.indexOf(cmp.statusId.toString()) > -1 && this.activeClassFilterCodes.indexOf("4") > -1;
    }));



    this.recountClassesSection(complaintsViewComplaints.filter((cmp) =>
      this.activeStatusFitlerCodes.indexOf(cmp.statusId.toString()) > -1 && cmp.roleCodes.filter(roleCode => this.activeRoleFitlerCodes.indexOf(roleCode) !== -1).length > 0
    ));

    complaintsViewComplaints.filter(cmp => {
      if (cmp.scoringMatrixCurrentRating != null && cmp.scoringMatrixCurrentRating != undefined) {
        if (cmp.complaintClass == null && cmp.scoringMatrixCurrentRating.class != 1) {
          return cmp.roleCodes.some(rc => this.activeRoleFitlerCodes.indexOf(rc))
            && this.activeStatusFitlerCodes.indexOf(cmp.statusId.toString()) > -1
            && this.activeClassFilterCodes.indexOf('5') > -1;
        }
        return this.activeClassFilterCodes.indexOf(cmp.scoringMatrixCurrentRating.class.toString()) > -1
          && cmp.roleCodes.some(rc => this.activeRoleFitlerCodes.includes(rc))
          && this.activeStatusFitlerCodes.indexOf(cmp.statusId.toString()) > -1;
      }
      else if (cmp.scoringMatrixOriginalRating != null && cmp.scoringMatrixOriginalRating != undefined) {
        if ((cmp.complaintClass == null || (cmp.scoringMatrixOriginalRating.class == 0 && cmp.statusId < 4)) && cmp.scoringMatrixOriginalRating.class != 1) {
          return cmp.roleCodes.some(rc => this.activeRoleFitlerCodes.includes(rc))
            && this.activeStatusFitlerCodes.indexOf(cmp.statusId.toString()) > -1
            && this.activeClassFilterCodes.indexOf('5') > -1;
        }
        return this.activeClassFilterCodes.indexOf(cmp.scoringMatrixOriginalRating.class.toString()) > -1
          && cmp.roleCodes.some(rc => this.activeRoleFitlerCodes.includes(rc))
          && this.activeStatusFitlerCodes.indexOf(cmp.statusId.toString()) > -1;
      }
      return cmp.roleCodes.some(rc => this.activeRoleFitlerCodes.includes(rc))
        && this.activeStatusFitlerCodes.indexOf(cmp.statusId.toString()) > -1
        && this.activeClassFilterCodes.indexOf('4') > -1;
    }).forEach(c => this.complaintsComplaintsViewFiltered.push(c));

    this.complaintsComplaintsViewFiltered = _.orderBy(this.complaintsComplaintsViewFiltered, [this.order_by], (this.order_mode === 'desc') ? 'desc' : 'asc');

  };

  refreshView() {
    this.cmpCustomInfoCodes = this.lclStorageService.getFromLocalStorage("cmpCustomInfo") || [1, 2, 3, 17]; //[Customer,RU,ABB Prod, Complaint Type]
    if (this.isActionsViewSelected) {
      this.refreshActionsView();
    }
    else if (this.isComplaintsViewSelected) {
      this.refreshComplaintsView();
    }
  }

  refreshSearchView(searchText: string) {
    this.search_keyword = searchText;
    this.refreshView();
  }

  refreshComplaintsWithSorting(orderMode: any, orderBy: any) {
    this.order_mode = orderMode;
    this.order_by = orderBy;
    this.refreshView();
  }

  // Place to fetch complaints from Service file

  loadComplaints(geid) {
    this.spinner.show();
    this.myissueService.getComplaintsForCurrentUser(geid).then(data => {
      this.complaints = data;
      this.refreshView();
      this.dataLoaded = true;
      this.spinner.hide();
    });

  }


  // Export to Excel Functionality methods

  isExportAvailable = function (): boolean {

    var isValid = false;

    if (this.dataLoaded) {
      if (this.isComplaintsViewSelected && this.statusesCount.total > 0 && this.activeClassFilterCodes.length > 0) {
        isValid = true;
      }

      if (this.isActionsViewSelected && this.actionsCount.total > 0) {
        isValid = true;
      }
    }

    return isValid;
  }

  returnIssueIds = function () {
    if (this.isActionsViewSelected) {
      return this.complaintsActionsViewFiltered.map(function (a) { return a.id; });
    }
    else {
      return this.complaintsComplaintsViewFiltered.map(function (a) { return a.id; });
    }
  }

  returnVisibleActionFilterCodes = function () {
    var activeStatuses = this.activeActionFilterCodes;
    if (this.tileVisibility.ro === false){
      const index = activeStatuses.indexOf("7");
      if(index !== -1){
        activeStatuses.splice(index,1)
      }
    }
    if (this.tileVisibility.co === false){
      const index = activeStatuses.indexOf("8");
      if(index !== -1){
        activeStatuses.splice(index,1)
      }
    }
    if (this.tileVisibility.ru === false){
      const index = activeStatuses.indexOf("9");
      if(index !== -1){
        activeStatuses.splice(index,1)
      }
    }
    if (this.tileVisibility.returnedToNa === false){
      const index = activeStatuses.indexOf("10");
      if(index !== -1){
        activeStatuses.splice(index,1)
      }
    }
    if (this.tileVisibility.toResendSurvey === false){
      const index = activeStatuses.indexOf("11");
      if(index !== -1){
        activeStatuses.splice(index,1)
      }
    }
    if (this.tileVisibility.toExecuteContainmentAction === false){
      const index = activeStatuses.indexOf("12");
      if(index !== -1){
        activeStatuses.splice(index,1)
      }
    }
    if (this.tileVisibility.toCompleteAfterCustomerExecution === false){
      const index = activeStatuses.indexOf("13");
      if(index !== -1){
        activeStatuses.splice(index,1)
      }
    }
    if (this.tileVisibility.toEffectivenessAndSustainabilityCheck === false){
      const index = activeStatuses.indexOf("14");
      if(index !== -1){
        activeStatuses.splice(index,1)
      }
    }
    return activeStatuses;
  }

  returnVisibleRoleFitlerCodes = function () {
    return this.activeRoleFitlerCodes;
  }

  returnVisibleStatusFilterCodes = function () {
    return this.activeStatusFitlerCodes;
  }

  returnVisibleClassFilterCodes = function () {
    return this.activeClassFilterCodes;
  }

  // Export to Excel

  ExportDashboardComplaints() {
    this.IsDashboardExcelGenerating = true;
    this.myissueService.downloadDashboardComplaintsExcel({
      'IssueIds': this.returnIssueIds(),
      'IsActionsViewSelected': this.isActionsViewSelected,
      'VisibleActionFilterCodes': this.returnVisibleActionFilterCodes(),
      'VisibleStatusFilterCodes': this.returnVisibleStatusFilterCodes(),
      'VisibleRoleFitlerCodes': this.returnVisibleRoleFitlerCodes(),
      'VisibleClassFilterCodes': this.returnVisibleClassFilterCodes()
    }).subscribe((response: ArrayBuffer) => {
      const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const downloadLink = document.createElement('a');
      downloadLink.href = window.URL.createObjectURL(blob);
      downloadLink.download = 'My Dashboard Issues Report';
      downloadLink.click();
      this.IsDashboardExcelGenerating = false;
    });

  }

}







