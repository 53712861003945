import * as i0 from '@angular/core';
import { Component, ChangeDetectionStrategy, ViewEncapsulation, Input } from '@angular/core';
import { ObjectUtils } from 'primeng/utils';
const _c0 = ["*"];
class BaseIcon {
  constructor() {
    this.spin = false;
  }
  ngOnInit() {
    this.getAttributes();
  }
  getAttributes() {
    const isLabelEmpty = ObjectUtils.isEmpty(this.label);
    this.role = !isLabelEmpty ? 'img' : undefined;
    this.ariaLabel = !isLabelEmpty ? this.label : undefined;
    this.ariaHidden = isLabelEmpty;
  }
  getClassNames() {
    return `p-icon ${this.styleClass ? this.styleClass + ' ' : ''}${this.spin ? 'p-icon-spin' : ''}`;
  }
}
BaseIcon.ɵfac = function BaseIcon_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || BaseIcon)();
};
BaseIcon.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: BaseIcon,
  selectors: [["ng-component"]],
  hostAttrs: [1, "p-element", "p-icon-wrapper"],
  inputs: {
    label: "label",
    spin: "spin",
    styleClass: "styleClass"
  },
  standalone: true,
  features: [i0.ɵɵStandaloneFeature],
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function BaseIcon_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BaseIcon, [{
    type: Component,
    args: [{
      template: ` <ng-content></ng-content> `,
      standalone: true,
      changeDetection: ChangeDetectionStrategy.OnPush,
      encapsulation: ViewEncapsulation.None,
      host: {
        class: 'p-element p-icon-wrapper'
      }
    }]
  }], null, {
    label: [{
      type: Input
    }],
    spin: [{
      type: Input
    }],
    styleClass: [{
      type: Input
    }]
  });
})();

/**
 * Generated bundle index. Do not edit.
 */

export { BaseIcon };

