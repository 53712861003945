import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-myactions-dashboard-role-filter',
  templateUrl: './myactions-dashboard-role-filter.component.html',
  styleUrls: ['./myactions-dashboard-role-filter.component.css']
})
export class MyactionsDashboardRoleFilterComponent implements OnInit {

  @Input() rolesInfo!:any;
  constructor() { }

  ngOnInit(): void {
  }

}
