<!-- <div my-actions-dashboard-search-bar order-mode="orderMode" order-by="orderBy" search-keyword="searchKeyword"></div> -->

<nav class="navbar navbar-default mb-0" role="navigation">
  <div class="row">
    <div class="col-md-9 actionsearch-textfeld">
      <form class="navbar-form ng-pristine ng-valid" _lpchecked="1">
        <div class="form-group" style="display:inline;">
          <div class="input-group">
            <input [(ngModel)]="searchKeyword" class="form-control ng-pristine ng-valid" name="search"
              placeholder="{{'my_actions_search_keyword_ph'|translate}}" autocomplete="off" type="text">
            <span class="input-group-addon button-set" style="background: #d9d9d9; margin: 2px; width: 44px;">
              <span class="hitachiicon-magnifying_glass"></span>
            </span>
          </div>
        </div>
      </form>
    </div>
    <div class="col-md-3 sorting-mobileview">
      <span class="navbar-form navbar-right ps-0" role="search">
        <span class="input-group dashboard-sort-control" style="margin-right: 15px;">
          <select class="form-control filterby-list" [(ngModel)]="orderBy" style="width:100%;">
            <option value="itemStatus">  {{'my_actions_sort_status'|translate}}  </option>
            <option value="actionTypeName">{{'my_actions_sort_action_type'|translate}}</option>
            <option value="ccrpNo">{{'my_actions_sort_ccrp_no'|translate}}</option>
            <option value="targetDate" selected>{{'my_actions_sort_deadline'|translate}}</option>
            <option value="resolutionOwner.name">{{'my_actions_sort_ro'|translate}}</option>
            <option value="resolvingUnit.divisionCode">{{'my_actions_sort_ru_div'|translate}}</option>
            <option value="resolvingUnit.buCode">{{'my_actions_sort_ru_bu'|translate}}</option>
            <option value="resolvingUnit.pgCode">{{'my_actions_sort_ru_pg'|translate}}</option>
            <i class="hitachiicon-sort_up"></i>
          </select>
          <span class="input-group-btn">
            <button *ngIf="orderMode =='asc'" (click)="orderMode = 'desc'"
              class="btn btn-default button-set dashboard-input-btn nav-field-btn"><i class="hitachiicon-sort_up"></i></button>
              <button *ngIf="orderMode =='desc'" (click)="orderMode = 'asc'"
              class="btn btn-default button-set dashboard-input-btn nav-field-btn"><i class="hitachiicon-sort_down"></i>
            </button>
          </span>
        </span>
      </span>
    </div>
  </div>
</nav>
<div class="row">
  <div class="col-sm-12">
      <span class="pull-right mb-2 mt-2" style="margin-right: 5px;">
          <a class="text-capitalize exportcls" *ngIf="displayedActions && displayedActions.length > 0 && !excelDownloading " (click)="exportActionItemsByGeid()"><span class="glyphicon glyphicon-save"></span>&nbsp;{{'my_actions_export_excel' | translate}}</a>
          <span *ngIf="excelDownloading">
            <i class="fa fa-circle-o-notch fa-spin"></i>
            &nbsp;{{'my_actions_export_excel_generating' | translate}}
        </span>
      </span>
  </div>
</div>

<div class="panel-body bg-white1">
<div class="row">
  <div class="col-lg-2 col-md-2 col-sm-2 ps-0 pe-0 removePadMobView left-panel">
    <div>
      <app-myactions-dashboard-status-filter [statusesInfo]="statusesInfo"></app-myactions-dashboard-status-filter>
    </div>
    <div>
      <app-myactions-dashboard-role-filter [rolesInfo]="rolesInfo"></app-myactions-dashboard-role-filter>
    </div>
  </div>
  <div class="col-sm-10 right-panel">
    <span *ngIf="displayedActions.length > 0 && statusesInfo.completed.isActive" class="text-danger">{{'my_actions_dashboard_completed_last_three_month' | translate}}</span>
    <div class="panel panel-default border-0">
      <div class="panel-body pt-0 ps-0 pe-0">
        <!-- <span *ngIf="displayedActions.length > 0" class="text-warning">Only actions that have been closed within last three months are being displayed.</span> -->
        <div *ngIf="dataLoaded">
          <app-my-actions-dashboard-list [ReceivedActions]="displayedActions" [changesApplied]="isSearchSomething"></app-my-actions-dashboard-list>
        </div>
      </div>
    </div>
  </div>
</div>
</div>
<ngx-spinner bdColor="rgba(51, 51, 51, 0.3)" color="#676767" size="medium" type="ball-spin-clockwise">
  <p style="color: #676767; padding: 10px; font-weight: bold; ">Please Wait... </p>
</ngx-spinner>

