import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MyActionDashboardService {

  constructor(private http: HttpClient) { }

  getActionsForUser(geid: string) {
    let params = new HttpParams()
      .set('geid', geid);
    return this.http.get<any>(`${environment.azure.baseUrl}api/v1/actiondashboard`, { params });

  }

  downloadMyActionBoardExcel(query:any) {

   return this.http.post(`${environment.azure.baseUrl}api/v1/actiondashboard/excel`,query,{
      responseType: 'arraybuffer'
    });
  }

}


