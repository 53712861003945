import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe} from '@angular/common'

@Pipe({
  name: 'customdate'
})
export class CustomdatePipe implements PipeTransform {

  constructor(private datePipe: DatePipe){}

  transform(value: any): any {
    if(value){
      const date = new Date(value);
      return this.datePipe.transform(date,'yyyy-MM-dd');
    }
    return null;
  }

}
