import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'daysleft'
})
export class DaysleftPipe implements PipeTransform {

  transform(value: string): any {
    if(value){
      // const today = new Date();
      //const date = new Date(value);
      // const diffTime = Math.abs(today.getTime()-date.getTime());
      // const diffDays = diffTime/ (1000*60*60*24);
      // const daysleft = Math.round(diffDays % 24)
      // if(daysleft==1){
      //   return `a day`
      // }else{
      //   return `${daysleft} day${daysleft === 1 ? '' : 's'}`;
      // }
      var date = moment(value);
      return moment(date).utc(true).fromNow(true);
    }
    return '';
  }

}
