import { Directive,ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[appPopupPlaceHolder]'
})
export class PopupPlaceHolderDirective {

  constructor(public viewContainerRef: ViewContainerRef) { }

}
