import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'daysago'
})
export class DaysagoPipe implements PipeTransform {

  transform(value: string): any {
    if(value){
      // const today = new Date();
      // const date =new Date(value);
      // const diffTime = Math.abs(today.getTime()-date.getTime());
      // const diffDays = Math.ceil(diffTime/ (1000*60*60*24))-1;

      // if(diffDays === 1){
      //   return `a day`
      // } if(diffDays >27){
      //   return `a month`
      // }
      // else{
      //   return `${diffDays} day${diffDays === 1 ? '' : 's'}`;
      // }
      var dateNow = moment();
      var dateFrom = moment(value);
      return dateNow.from(moment(dateFrom).utc(true), true)
    }
    return '';
  }

}
