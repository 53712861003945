<div class="panel panel-default">
  <div class="panel-heading panel-space text-center">
    <!-- {{'cc_myactions_status_filter' | translate}} -->
    Status
  </div>
  <ul class="nav nav-pills nav-stacked">
    <li class="badge-info">
    <li (click)="statusesInfo.draft.isActive = !statusesInfo.draft.isActive"
      [ngClass]="{'active': statusesInfo.draft.isActive}">
      <a>
        <span class="fa admin-link"
          [ngClass]="{'fa-square-o ':!statusesInfo.draft.isActive, 'fa-check-square-o ': statusesInfo.draft.isActive }"></span>
        <span class="badge pull-right">{{statusesInfo.draft.count}}</span>
        {{'action_status_draft_lbl' | translate}}
      </a>
    </li>
    <li class="badge-info">
    <li (click)="statusesInfo.inProgress.isActive = !statusesInfo.inProgress.isActive"
      [ngClass]="{'active': statusesInfo.inProgress.isActive}">
      <a>
        <span class="fa admin-link"
          [ngClass]="{'fa-square-o ':!statusesInfo.inProgress.isActive, 'fa-check-square-o ': statusesInfo.inProgress.isActive }"></span>
        <span class="badge pull-right">{{statusesInfo.inProgress.count}}</span>
        {{'action_status_in_progress_lbl' | translate}}
      </a>
    </li>
    <li class="badge-info">
    <li (click)="statusesInfo.completed.isActive = !statusesInfo.completed.isActive"
      [ngClass]="{'active': statusesInfo.completed.isActive}">
      <a>
        <span class="fa admin-link"
          [ngClass]="{'fa-square-o ':!statusesInfo.completed.isActive, 'fa-check-square-o ': statusesInfo.completed.isActive }"></span>
        <span class="badge pull-right">{{statusesInfo.completed.count}}</span>
        {{'action_status_completed_lbl' | translate}}
      </a>
    </li>
  </ul>
</div>
