{
  "name": "ccrp-web",
  "version": "4.0.0",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "build": "ng build",
    "watch": "ng build --watch --configuration development",
    "test": "ng test"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^18.2.2",
    "@angular/common": "^18.2.2",
    "@angular/compiler": "^18.2.2",
    "@angular/core": "^18.2.2",
    "@angular/forms": "^18.2.2",
    "@angular/localize": "^18.2.2",
    "@angular/platform-browser": "^18.2.2",
    "@angular/platform-browser-dynamic": "^18.2.2",
    "@angular/router": "^18.2.2",
    "@azure/msal-angular": "^3.0.23",
    "@azure/msal-browser": "^3.22.0",
    "@microsoft/applicationinsights-angularplugin-js": "^3.0.0",
    "@microsoft/applicationinsights-web": "^2.8.9",
    "@ng-bootstrap/ng-bootstrap": "^17.0.0",
    "@ngx-translate/core": "^15.0.0",
    "@ngx-translate/http-loader": "^8.0.0",
    "@popperjs/core": "^2.10.2",
    "angular-user-idle": "^4.0.0",
    "bootstrap": "^5.2.3",
    "file-saver": "^2.0.5",
    "json-server": "^0.17.2",
    "lodash": "^4.17.6",
    "moment": "^2.29.4",
    "ngx-bootstrap": "18.0.2",
    "ngx-export-as": "^1.16.0",
    "ngx-infinite-scroll": "^18.0.0",
    "ngx-pagination": "^6.0.3",
    "ngx-spinner": "^17.0.0",
    "primeicons": "^7.0.0",
    "primeng": "^15.2.0",
    "quill": "^1.3.7",
    "rxjs": "~7.8.1",
    "tslib": "^2.3.0",
    "xlsx": "^0.18.5",
    "zone.js": "~0.14.10"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.2.1",
    "@angular/cli": "^18.2.1",
    "@angular/compiler-cli": "^18.2.2",
    "@types/jasmine": "~3.10.0",
    "@types/lodash": "^4.14.192",
    "@types/node": "^12.11.1",
    "jasmine-core": "~4.0.0",
    "karma": "~6.3.0",
    "karma-chrome-launcher": "~3.1.0",
    "karma-coverage": "~2.1.0",
    "karma-jasmine": "~4.0.0",
    "karma-jasmine-html-reporter": "~1.7.0",
    "typescript": "~5.4.5"
  }
}
