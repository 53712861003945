import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageServiceService {

  constructor() { }

  localStoragePrefix = 'ls_p_';
  storageType = 'localStorage';
  EXPIRATIONS_KEY = 'localStorageExpirations';

  isBrowserSupportingLocalStorage = (function () {

    try {

        let supported = (typeof Storage !== "undefined");
        const localsStoragePrefix = 'ls_p_';
        let key = localsStoragePrefix + '__' + Math.round(Math.random() * 1e7);
        if (supported) {
           localStorage.setItem(key, '');
           localStorage.removeItem(key);
        }
        return true;
    } catch (e) {
        this.storageType = 'cookie';
        return false;
    }
}());

setUpExpiration = function (expirationKey, exp) {
  var now = new Date();
  var expireDate = new Date();

  if (typeof exp == 'undefined' || null == exp) {
      expireDate.setDate(now.getDate() + 1); // default to one day from now
  } else {
      if (exp.minutes) {
          expireDate.setMinutes(now.getMinutes() + exp.minutes);
      }
      if (exp.hours) {
          expireDate.setHours(now.getHours() + exp.hours);
      }
      if (exp.days) {
          expireDate.setDate(now.getDate() + exp.days);
      }
      if (exp.months) {
          expireDate.setMonth(now.getMonth() + exp.months);
      }
      if (exp.years) {
          expireDate.setFullYear(now.getFullYear() + exp.years);
      }
  }

  this.setExpiration(expirationKey, expireDate);
}

setExpiration = function (key, expireDate) {
  var expirations = localStorage.getItem(this.EXPIRATIONS_KEY);
  if (expirations) {
      var arr = expirations.split("|"); // ["key1^11/18/2011 5pm","key2^3/10/2012 3pm"]
      for (var i = 0; i < arr.length; i++) {
          var expiration = arr[i]; // "key1^11/18/2011 5pm"
          if (expiration.split('^')[0] == key) { // found match; update expiration
              arr.splice(i, 1); // remove, we'll add it w/ updated expiration later
              break;
          }
      } // next: key^exp pair
      arr.push(key + "^" + expireDate.toString());
      localStorage.setItem(this.EXPIRATIONS_KEY, arr.join("|"));
  } else {
    localStorage.setItem(this.EXPIRATIONS_KEY, key + "^" + expireDate.toString());
  }
};

getExpiration = function (key) {
  var expirations = localStorage.getItem(this.EXPIRATIONS_KEY); // "key1^11/18/2011 5pm|key2^3/10/2012 3pm"
  if (expirations) {
      var arr = expirations.split("|"); // ["key1^11/18/2011 5pm","key2^3/10/2012 3pm"]
      for (var i = 0; i < arr.length; i++) {
          var expiration = arr[i]; // "key1^11/18/2011 5pm"
          var k = expiration.split('^')[0]; // key1
          var e = expiration.split('^')[1]; // 11/18/2011 5pm
          if (k == key) { // found match; return expiration and remove expiration if it's expired
              var now = new Date();
              var eDate = new Date(e);
              if (now > eDate) {
                  // remove expiration
                  arr.splice(i, 1);
                  if (arr.length > 0) {
                    localStorage.setItem(this.EXPIRATIONS_KEY, arr.join("|"));
                  } else {
                    localStorage.removeItem(this.EXPIRATIONS_KEY);
                  }
              }
              return new Date(e);
          }
      } // next: key^exp pair
  }
  return null;
};

addToLocalStorage = function (key:any, value:any, expired?:any) {

  if (this.isBrowserSupportingLocalStorage) {

      if (typeof value === "undefined") {
          value = null;
      }

      try {
          if (typeof value === 'object') {
              value = JSON.stringify(value);
          }
          var prefixedKey = this.localStoragePrefix + key;
          localStorage.setItem(prefixedKey, value);
          this.setUpExpiration(prefixedKey, expired);
          return true;
      } catch (e) {
          //addToCookies(key, value, expired);
          return false;
      }
  } else {
      //addToCookies(key, value, expired);
      return false;
  }
};

// Returns value from local storage
// If data expired returns null
// ex: ccLocalStorageService.get("favColor")
getFromLocalStorage = function (key:any) {
  if (this.isBrowserSupportingLocalStorage) {
      var prefixedKey = this.localStoragePrefix + key;
      var now = new Date();
      var expireDate = this.getExpiration(prefixedKey);

      if (expireDate && now <= expireDate) {
          var itemFromStorage = localStorage.getItem(prefixedKey);
          if (!itemFromStorage || itemFromStorage === 'null') {
              return null;
          }

          if (itemFromStorage.charAt(0) === "{" || itemFromStorage.charAt(0) === "[") {
              return JSON.parse(itemFromStorage);
          }

          return itemFromStorage;
      }

      this.removeFromLocalStorage(key); //remove item from localstorage
  }

  return null;
};


// Remove value from local storage
// returns true if operation succeed
// ex: ccLocalStorageService.remove("favColor")
removeFromLocalStorage = function (key) {
  if (this.isBrowserSupportingLocalStorage) {
      var prefixedKey = this.localStoragePrefix + key;
      try {
          localStorage.removeItem(prefixedKey);
          return true;
      } catch (e) {
          return false;
      }
  }
  return false;
};

}
