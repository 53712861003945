import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class EmployeeListService {

  constructor(public http: HttpClient) { }
  getEmployeeDetails() {
    // return this.http.get('http://localhost:3000/EmailList');
    return this.http.get(`${environment.API_URL}/EmailList`);
  }

  employeeDashboardDetails(query: any): Promise<any> {
    let params = new HttpParams();
    let ab = '';
    query.forEach((element, index, array) => {
      if (index == 0) {
        ab += '' + element
      }
      else {
        ab += ',' + element
      }
    })
    params = params.append('geids', ab);
    return new Promise((resolve, reject) => {
      this.http.post(`${environment.azure.baseUrl}api/v1/dashboard/employeeinfo`, { geids: ab} ).toPromise().then((data: any) => {
        resolve(data);
      }).catch((error: any) => {
        reject(error);
      })
    });
    }


    processOwnerdDetails(query):Promise<any> {

      let params = new HttpParams();
      const queries = query.map(str => 'geids='+str).join('&')

      return new Promise<any>((resolve,reject)=>{
        this.http.get(`${environment.azure.baseUrl}api/v1/processowner/processownerinfo?${queries}`,{params}).subscribe(
          (data)=>{
            resolve(data);
          }
        )
      })
  }

}
