import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { LocalStorageServiceService } from 'src/app/services/local-storage-service.service';

@Component({
  selector: 'app-my-actions-dashboard-list',
  templateUrl: './my-actions-dashboard-list.component.html',
  styleUrls: ['./my-actions-dashboard-list.component.css']
})
export class MyActionsDashboardListComponent implements OnInit {

  @Input() ReceivedActions: any[];
  @Input() changesApplied: boolean;
  actions: any[];
  actionsToPresent!: any;
  config: any;
  firstPage: number = 1;
  currentPage: number = 1;
  pagesCount: number;
  resultsPerPage: number = 5;

  ngOnChanges(changes: SimpleChanges) {
    this.actions = this.ReceivedActions;
    this.pagesCount = (this.actions != null && this.actions.length > 0)
      ? Math.ceil(this.actions.length / this.resultsPerPage) : 1;
    this.refreshPresentedActions();
  }

  constructor(private lclStorageService: LocalStorageServiceService) {
    this.config = {
      currentPage: 1,
      itemsPerPage: this.lclStorageService.getFromLocalStorage("cmpPageSize") || 5
    };
  }

  ngOnInit(): void {
    //this.actionsToPresent = this.actions.slice();
  }

  refreshPresentedActions() {
    if (this.currentPage == 1) {
      let startIdx = this.currentPage == 1 ? 0 : (this.currentPage - 1) * this.resultsPerPage;
      let endIdx = this.currentPage == 1 ? this.resultsPerPage : (this.currentPage - 1) * this.resultsPerPage + this.resultsPerPage;
      this.actionsToPresent = this.actions.slice(startIdx, endIdx);
    } else {
      if (this.pagesCount >= this.currentPage) {
        let startIdx = this.currentPage == 1 ? 0 : (this.currentPage - 1) * this.resultsPerPage;
        let endIdx = this.currentPage == 1 ? this.resultsPerPage : (this.currentPage - 1) * this.resultsPerPage + this.resultsPerPage;
        this.actionsToPresent = this.actions.slice(startIdx, endIdx);
      } else {
        this.currentPage = 1;
        let startIdx = this.currentPage == 1 ? 0 : (this.currentPage - 1) * this.resultsPerPage;
        let endIdx = this.currentPage == 1 ? this.resultsPerPage : (this.currentPage - 1) * this.resultsPerPage + this.resultsPerPage;
        this.actionsToPresent = this.actions.slice(startIdx, endIdx);

      }

    }

  }

  hasPrevious() {
    return this.currentPage > 1;
  }
  previousPage() {
    if (this.hasPrevious()) {
      return this.currentPage - 1;
    }
    return 0;
  }
  previousClicked() {
    if (this.hasPrevious()) {
      this.currentPage--;
      this.refreshPresentedActions();
    }
  }

  showFirstPage() {
    return this.hasPrevious() && this.previousPage() > 1;
  }
  gotoPage(page) {
    this.currentPage = page;
    this.refreshPresentedActions();
  }
  hasNext() {
    return this.currentPage < this.pagesCount;
  }
  nextPage() {
    if (this.hasNext()) {
      return this.currentPage + 1;
    }
    return 0;
  }
  showFirstPageSeparator() {
    return this.currentPage > 3;
  }
  showLastPageSeparator() {
    return this.hasNext() && this.nextPage() < this.pagesCount - 1;
  }
  showLastPage() {
    return this.hasNext() && this.nextPage() < this.pagesCount;
  }
  nextClicked() {
    if (this.hasNext()) {
      this.currentPage++;
      this.refreshPresentedActions();
    }
  }
}
