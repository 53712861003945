import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-myactions-dashboard-status-filter',
  templateUrl: './myactions-dashboard-status-filter.component.html',
  styleUrls: ['./myactions-dashboard-status-filter.component.css']
})
export class MyactionsDashboardStatusFilterComponent implements OnInit {

  @Input() statusesInfo!:any;

  constructor() { }

  ngOnInit(): void {
  }

}
