import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { EmployeeListService } from './employee-list.service';
import * as _ from 'lodash';
import moment from 'moment';

import { SessionStorageServiceService } from './session-storage-service.service';
import { ComplaintModel } from '../components/new-issue/Model/ComplaintModel';
import { BehaviorSubject, map } from 'rxjs';

export const ComplaintStatuses: {[key:string]:string} = {
"1":"New",
"2":"Draft",
"3":"Not Assigned",
"4":"In Process",
"5":"In Process Overdue",
"6":"In Process ReOpen",
"7":"Resolved",
"8":"Completed",
"9":"Cancelled",
"11":"In Process Verification",
"12":"In Process Verification Overdue",
"13":"Completed (Execution Pending)"

}

export interface CustomInformationCodes {
  code: number,
  label: string,
  isCmpInfo: boolean,
  isSelected: boolean,
  $$hashKey: string
}

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  issues: any[];

  headers = new HttpHeaders({
    'Authorization': `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsIng1dCI6Ii1LSTNROW5OUjdiUm9meG1lWm9YcWJIWkdldyIsImtpZCI6Ii1LSTNROW5OUjdiUm9meG1lWm9YcWJIWkdldyJ9.eyJhdWQiOiJhcGk6Ly85YjU2YzMyOC0wNGZkLTQ0MWYtOWYxMy02ZTA1ZThkMDBjY2EiLCJpc3MiOiJodHRwczovL3N0cy53aW5kb3dzLm5ldC83ODMxZTZkOS1kYzZjLTRjZDEtOWVjNi0xZGMyYjQxMzMxOTUvIiwiaWF0IjoxNjg0NzY4NDIzLCJuYmYiOjE2ODQ3Njg0MjMsImV4cCI6MTY4NDc3MzkyNiwiYWNyIjoiMSIsImFpbyI6IkFWUUFxLzhUQUFBQXk0THJ6bjBSVGVPYS9pK2JUbmVaMzF1dy9NSkNUY1p6YXRySDJGa3E2clA1OElLQ2gwRyt3aG1YRDFGOUF2Z1lSRm5MT0kwZ0ZkT2ZKaXVSTHdaajFoUTY0cmNvQU9Hb0pqSmJJdENQYXNjPSIsImFtciI6WyJwd2QiXSwiYXBwaWQiOiI0MjVlMWUzYS0wNTA4LTQwNDgtODg3ZC04ZDhiODA3Njk1Y2YiLCJhcHBpZGFjciI6IjAiLCJmYW1pbHlfbmFtZSI6IktvbmRhcGFsbGkiLCJnaXZlbl9uYW1lIjoiUmFtIiwiaXBhZGRyIjoiMTM4LjIyNS4xODkuMTUiLCJuYW1lIjoiUmFtIEtvbmRhcGFsbGkiLCJvaWQiOiI1ZWViZGRkNC00NTMwLTRlZjItODBkZS1jNTgxZmNmZDRjNzMiLCJvbnByZW1fc2lkIjoiUy0xLTUtMjEtNDIyNzU5MzYzLTM0NzUzODc0NDktMjY2NDE2OTQzOS05NzEzMCIsInJoIjoiMC5BVWNBMmVZeGVHemMwVXlleGgzQ3RCTXhsU2pEVnB2OUJCOUVueE51QmVqUURNcEhBRVUuIiwic2NwIjoiYXBpLnNjb3BlIiwic3ViIjoiWkZEa0lJdFNkNHhwckduTHhJbE1LQVpHOVpTdDRZbVF5a0xwYlZvYzFFQSIsInRpZCI6Ijc4MzFlNmQ5LWRjNmMtNGNkMS05ZWM2LTFkYzJiNDEzMzE5NSIsInVuaXF1ZV9uYW1lIjoicmFtLmtvbmRhcGFsbGlAaGl0YWNoaWVuZXJneS5jb20iLCJ1cG4iOiJyYW0ua29uZGFwYWxsaUBoaXRhY2hpZW5lcmd5LmNvbSIsInV0aSI6IjdNR2ZWSThmZGt1cEp6ZFJyMVlQQUEiLCJ2ZXIiOiIxLjAifQ.Yja2_Up8I6GUuxMcF_VLZeNwiXbpy45AUWQTt2_9E8Z7fswIskyqlwr9RL_Tz2hNjd-cTYIfp4dHcb5sYk5qJ5v1fOUmVz41OKJG_-2qOXbpdvPJ5k4s_T8biX9SyrZdYGy1irwRdtOJgCRAVkzUtKzUR2xlZuzxRN_seMyxfoxSlTT_C9J-uXKblwpkBvuXW9Xgh8VrV9t8LqRNXdqJe_D7AsIa1AEKGzsHpUrLRQJp4uQ_ZvfhFZQkgQfYZN1wO1t3Bqc-ucg_6Em_90omcjooB9awAYyvrpud8Sob61FWaCwyuuLt7PoJx4CfgmH0_P4NkIzICfwoDwBeeqeuvg`
  });
 requestOptions = { headers: this.headers };

  public getGeid = new BehaviorSubject<String>('');
  isGetGeid = this.getGeid.asObservable();

  constructor(private http: HttpClient, private empSerivce: EmployeeListService,
    private sessionStorage: SessionStorageServiceService) { }

  setGeid(geid){
    this.getGeid.next(geid);
  }

  getCustomInfoItems() {
    return this.http.get<CustomInformationCodes[]>(environment.azure.baseUrl + 'api/v1/dashboard/settings/custominfoitems');
  }

  getComplaintsForCurrentUser(geid): Promise<any> {

    let params = new HttpParams()
    .set('geid', geid);

    // return new Promise((resolve, reject) => {
    //   this.http.get(`${environment.azure.baseUrl}api/v1/dashboard/complaints`,{params}).subscribe(
    //     (data: any) => {
    //       this.processComplaintsData(data).then((processedData) => {
    //         resolve(processedData);
    //       }).catch((error) => {
    //         reject(error);
    //       });
    //     },
    //     (error: any) => {
    //       reject(error);
    //     }
    //   );
    // });

    return new Promise((resolve, reject) => {
      this.http.get(`${environment.azure.baseUrl}api/v1/dashboard/complaints`,{params}).subscribe(
        (data: any) => {
          this.processComplaintsData(data).then((processedData) => {
            let complaintClassData=[];
            this.http.get(`${environment.azure.baseUrl}api/v1/dashboard/complaintsMatrix`,{params}).subscribe(
              (resp:any)=>{
                complaintClassData = resp;
                const finalComplaintData = processedData.map(item1=>{
                  const matchingItem = complaintClassData.find(item2 => item2.id === item1.id);
                  return {...item1,
                    ...matchingItem
                          }
                });
                resolve(finalComplaintData);
              }
            )
          }).catch((error) => {
            reject(error);
          });
        },
        (error: any) => {
          reject(error);
        }
      );
    });
  }


  processComplaintsData(data: any): Promise<any> {

    return new Promise((resolve, reject) => {
      const geids = [];
      data.forEach((cmp: any) => {

        geids.push(cmp.complaintOwnerGeid);
        geids.push(cmp.resolutionOwnerGeid);
        cmp.complaintOwnerTeamGeids.forEach( (geid:any) => {
          geids.push(geid);
        });
        cmp.resolutionOwnerTeamGeids.forEach((geid:any) => {
          geids.push(geid);
        });
        cmp.coordinatorGeids.forEach((geid:any) => {
          geids.push(geid);
        });
      });

      this.empSerivce.employeeDashboardDetails(_.uniq(geids)).then((empInfo: any) => {
          data.forEach((cmp: any) => {
          cmp.complaintOwner = _.find(empInfo, function (e) { return cmp.complaintOwnerGeid == e.id; });
          cmp.resolutionOwner = _.find(empInfo, function (e) { return cmp.resolutionOwnerGeid == e.id; });

          if (cmp.coordinatorGeids) {
            var coordinators = [];
            cmp.coordinatorGeids.forEach((coor_geid: any) => {
              coordinators.push(empInfo.find((e: any) => coor_geid == e.id));
            })

            cmp.coordinators = coordinators;
          }

          if (cmp.complaintOwnerTeamGeids) {
            var coTeam = [];
            cmp.complaintOwnerTeamGeids.forEach((coteam_geid: any) => {
              coTeam.push(empInfo.find((e: any) => coteam_geid == e.id));
            })

            cmp.complaintOwnerTeam = coTeam;
          }

          if (cmp.resolutionOwnerTeamGeids) {
            var roTeam = [];
            cmp.resolutionOwnerTeamGeids.forEach((roteam_geid: any) => {
              roTeam.push(empInfo.find((e: any) => roteam_geid == e.id));
            })
            cmp.resolutionOwnerTeam = roTeam;
          }

          if (cmp.assignmentProgress) {
            cmp.assignmentProgress.descr = this.calculateDateDiffDescription(moment.utc(cmp.assignmentProgress.deadline), moment());
            cmp.statusInfo = cmp.assignmentProgress.status;
        }
        if (cmp.resolutionProgress) {
            cmp.resolutionProgress.descr = this.calculateDateDiffDescription(moment.utc(cmp.resolutionProgress.deadline), moment());
            cmp.statusInfo = cmp.resolutionProgress.status;
        }
        if (cmp.resolutionRCAProgress) {
            //nothing to be set
        }
        if (cmp.completionProgress) {
            cmp.completionProgress.descr = this.calculateDateDiffDescription(moment.utc(cmp.completionProgress.deadline), moment());
            cmp.statusInfo = cmp.completionProgress.status;
        }
        if (cmp.verificationProgress) {
            cmp.verificationProgress.descr = this.calculateDateDiffDescription(moment.utc(cmp.verificationProgress.deadline), moment());
            cmp.statusInfo = cmp.verificationProgress.status;
        }
        if (cmp.pendingCustomerExecutionProgress) {
          cmp.pendingCustomerExecutionProgress.descr = this.calculateDateDiffDescription(moment.utc(cmp.pendingCustomerExecutionProgress.deadline),
            moment());
          cmp.statusInfo = cmp.pendingCustomerExecutionProgress.status;
        }

        if(cmp.statusId){
         cmp.statusName = ComplaintStatuses[cmp.statusId];

        }

        });
        resolve(data);
      }).catch((error) => {
        reject(error);
      });

    });
  }


  calculateDateDiffDescription = function (dateFrom, dateTo) {

    var description = '';
    if (!dateFrom || !dateTo) {
      return '';
    }

    if (dateFrom.isBefore(dateTo)) {
      description += '-';
    }
    var diffHours = dateFrom.diff(dateTo, 'hours');

    var days = Math.abs(Math.floor(diffHours / 24));
    var hours = Math.abs(diffHours % 24);

    if (days && days != 0) {
      description += (days + 'd');
    }
    if (hours && hours != 0) {
      description += (' ' + hours + 'h');
    } else {
      description += (' 1h');
    }
    return description;
  }


  getComplaintData(complaintId:number = 0, complaintNumber: string = '') {
    if(complaintId==0 && complaintNumber==''){
      return null;
    }
    else if(complaintId!=0){
      return this.http.get<any>(`${environment.azure.baseUrl}api/v1/Complaint?id=${complaintId}`,this.requestOptions);
    }
    else{
      return this.http.get<any>(`${environment.azure.baseUrl}api/v1/Complaint?complaintNumber=${complaintNumber}`,this.requestOptions);
    }

  }

  getReadOnlyComplaintDetails(complaintId:number = 0, complaintNumber: string = '') {
    if(complaintId==0 && complaintNumber==''){
      return null;
    }
    else if(complaintId!=0){
      return this.http.get<any>(`${environment.azure.baseUrl}api/v1/Complaint/readonly?id=${complaintId}`,this.requestOptions);
    }
    else{
      return this.http.get<any>(`${environment.azure.baseUrl}api/v1/Complaint/readonly?complaintNumber=${complaintNumber}`,this.requestOptions);
    }

  }

  downloadDashboardComplaintsExcel(exportData:any) {

    const data =
    {
        'Geid': this.sessionStorage.getData('userInfo').geid,
        'IssueIds': exportData.IssueIds,
        'IsActionsViewSelected': exportData.IsActionsViewSelected,
        'ActiveActionFilterCodes': exportData.VisibleActionFilterCodes,
        'ActiveRoleFitlerCodes': exportData.VisibleRoleFitlerCodes,
        'ActiveStatusFilterCodes': exportData.VisibleStatusFilterCodes,
        'ActiveClassFilterCodes': exportData.VisibleClassFilterCodes
    }
    return this.http.post(`${environment.azure.baseUrl}api/v1/dashboard/exporting/complaints`,data,{
      responseType: 'arraybuffer'
    });
  }
}
