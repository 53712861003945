<div class="panel panel-default">
  <div class="panel-heading panel-space text-center">
    {{'cc_myactions_role_filter' | translate}}
  </div>
  <ul class="nav nav-pills nav-stacked">
    <li badge="badge-info">
      <li (click)="rolesInfo.actionOwner.isActive = !rolesInfo.actionOwner.isActive" [ngClass]="{'active': rolesInfo.actionOwner.isActive}">
          <a>
              <span class="fa admin-link" [ngClass]="{'fa-square-o ':!rolesInfo.actionOwner.isActive, 'fa-check-square-o ': rolesInfo.actionOwner.isActive }"></span>
              <span class="badge pull-right">{{rolesInfo.actionOwner.count}}</span>
              {{'action_role_owner_lbl' | translate}}
          </a>
      </li>
      <li badge="badge-info">
      <li (click)="rolesInfo.actionCreator.isActive = !rolesInfo.actionCreator.isActive" [ngClass]="{'active': rolesInfo.actionCreator.isActive}">
          <a>
              <span class="fa admin-link" [ngClass]="{'fa-square-o ':!rolesInfo.actionCreator.isActive, 'fa-check-square-o ': rolesInfo.actionCreator.isActive }"></span>
              <span class="badge pull-right">{{rolesInfo.actionCreator.count}}</span>
              {{'action_role_creator_lbl' | translate}}
          </a>
      </li>
  </ul>
</div>
